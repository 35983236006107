import disciplines from '@/assets/disciplines.json';
import stageDisciplinesMapping from '@/assets/stage-disciplines-mapping.json';

const mapping = {
  stage: stageDisciplinesMapping,
};

function getDisciplineCode(disciplineCode, codeMapping) {
  if (!disciplineCode) {
    return null;
  }

  return codeMapping && mapping[codeMapping]
    ? mapping[codeMapping][disciplineCode]
    : disciplineCode;
}

function getDiscipline(disciplineCode, codeMapping) {
  if (!disciplineCode) {
    return null;
  }

  const code = getDisciplineCode(disciplineCode, codeMapping);
  return disciplines[code] || null;
}

function getDisciplineIcon(disciplineCode, codeMapping) {
  return getDiscipline(disciplineCode, codeMapping)?.icon || null;
}

export {
  getDisciplineCode,
  getDiscipline,
  getDisciplineIcon,
};
