<template>
  <div class="footer">
    <div class="footer__container container">
      <div class="footer__items">
        <div class="footer__item" v-if="userbackAvailable">
          <Link
            tag="button"
            class="color-white v-align-middle"
            icon-left="feedback"
            variant="inherit"
            size="sm"
            :text="$t('website_feedback')"
            @click="openFeedbackForm"
          ></Link>
        </div>
        <div class="footer__item">
          <div class="footer__social">
            <a
              v-for="item in socials"
              :key="item.icon"
              class="footer__social-item"
              :href="item.link"
              target="_blank"
            >
              <Icon :name="item.icon"></Icon>
            </a>
          </div>
        </div>
        <div class="footer__item" v-if="languages.length > 1">
          <Button
            ref="langSwitch"
            variant="ghost"
            view="light"
            size="sm"
            :text="currentLang.text"
            icon-left="global"
          ></Button>
          <Dropdown
            trigger="langSwitch"
            placement="top-end"
            hide-overlay
            auto-width
          >
            <template slot="items">
              <div
                class="base-dropdown__item"
                :class="{'d-none': $lang === item.id}"
                v-for="item in languages"
                :key="item.id"
              >
                <a
                  class="base-dropdown__link"
                  @click.prevent="changeLang(item.id)"
                >
                  <span class="base-dropdown__icon flag-img">
                    <img
                      :src="require(`@/assets/img/langs/${item.id}.jpg`)"
                      :alt="item.id"
                    >
                  </span>
                  <span class="base-dropdown__text">{{ item.text }}</span>
                </a>
              </div>
            </template>
          </Dropdown>
        </div>
        <div class="footer__item footer__copyright">
          <span>{{ copyright }}</span>
          <template
            v-for="(link, i) in links"
          >
            &nbsp;&nbsp;|&nbsp;
            <template v-if="link.caption">{{ link.caption }}</template>

            <Link
              :key="i"
              class="color-white"
              :text="link.text"
              :href="link.link"
              target="_blank"
              variant="inherit"
              size="sm"
            ></Link>
          </template>
        </div>
      </div>

      <div v-if="partners.length > 1" class="footer__partners">
        <div
          v-for="partner in partners"
          class="footer__partners-item"
          :key="partner"
        >
          <img
            :src="require(`@/assets/img/${partner.img}.png`)"
            :srcset="require(`@/assets/img/${partner.img}_2x.png`) + ' 2x'"
            :alt="partner.img"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import Dropdown from '@/components/Dropdown/Dropdown.vue';

export default {
  name: 'Footer',
  components: {
    Dropdown,
  },
  i18n: {
    messages: {
      ru: {
        website_feedback: 'Отзыв о&nbsp;сайте',
      },
      en: {
        website_feedback: 'Website Feedback',
      },
    },
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeSettings', 'userbackAvailable']),
    data() {
      try {
        // eslint-disable-next-line global-require,import/no-dynamic-require
        const themeData = require(`./themes/${this.theme}/data.json`);
        return themeData[this.$lang] || themeData.ru || {};
      } catch {
        throw Error(`Footer component data is not defined for the ${this.theme} theme`);
      }
    },
    copyright() {
      return `© ${new Date().getFullYear()}, ${this.data.copyright || ''}`;
    },
    socials() {
      return this.data.socials || [];
    },

    partners() {
      return this.data.partners || [];
    },

    links() {
      return this.data.links || [];
    },
    languages() {
      return [
        { id: 'ru', text: 'Русский' },
        { id: 'en', text: 'English' },
        { id: 'uz', text: 'O\'zbek' },
      ].filter((o) => (this.themeSettings.languages || []).indexOf(o.id) !== -1);
    },
    currentLang() {
      return this.languages.find((o) => (o.id === this.$lang)) || {};
    },
  },
  methods: {
    changeLang(lang) {
      setCulture(lang);
    },
    openFeedbackForm() {
      if (window.Userback) {
        window.Userback.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./Footer";
</style>
