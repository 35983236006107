import { ResultRoundingRule } from '@/enums';

function isRoundingWithMilliseconds(roundingRule) {
  return [
    ResultRoundingRule.HundredthsUp,
    ResultRoundingRule.HundredthsDown,
    ResultRoundingRule.TenthsUp,
    ResultRoundingRule.TenthsDown,
    ResultRoundingRule.Thousandths,
  ]
    .some((value) => value === roundingRule);
}

function convertMsToTimeObj(milliseconds, roundingRule) {
  const rounding = Object.values(ResultRoundingRule).indexOf(roundingRule) !== -1
    ? roundingRule
    : ResultRoundingRule.Default;

  let secondsRoundingMethod = 'floor';

  if (rounding === ResultRoundingRule.Default) {
    secondsRoundingMethod = 'round';
  }

  if (rounding === ResultRoundingRule.Up) {
    secondsRoundingMethod = 'ceil';
  }

  let ms = isRoundingWithMilliseconds(rounding) ? milliseconds % 1000 : 0;
  let s = Math[secondsRoundingMethod]((milliseconds - ms) / 1000);

  if (rounding === ResultRoundingRule.TenthsUp) {
    ms = Math.ceil(ms / 100);
    if (ms === 10) {
      ms = 0;
      s += 1;
    }
  }

  if (rounding === ResultRoundingRule.TenthsDown) {
    ms = Math.floor(ms / 100);
  }

  if (rounding === ResultRoundingRule.HundredthsUp) {
    ms = Math.ceil(ms / 10);
    if (ms === 100) {
      ms = 0;
      s += 1;
    }
  }

  if (rounding === ResultRoundingRule.HundredthsDown) {
    ms = Math.floor(ms / 10);
  }

  let m = Math.floor(s / 60);
  s %= 60;
  const h = Math.floor(m / 60);
  m %= 60;

  return {
    hours: h,
    minutes: m,
    seconds: s,
    milliseconds: ms,
  };
}

function formatMilliseconds(milliseconds, options) {
  if (milliseconds == null) {
    return '';
  }

  const {
    roundingRule,
    fillHours,
  } = options || {};

  const rounding = Object.values(ResultRoundingRule).indexOf(roundingRule) !== -1
    ? roundingRule
    : ResultRoundingRule.Default;

  const timeObj = convertMsToTimeObj(milliseconds, rounding);
  const pad = (n, l) => (`00${n}`).slice(-l);

  let result = `${pad(timeObj.minutes, 2)}:${pad(timeObj.seconds, 2)}`;

  if (timeObj.hours || fillHours) {
    result = `${pad(timeObj.hours, 2)}:${result}`;
  }

  if (isRoundingWithMilliseconds(rounding)) {
    let msLength;

    switch (rounding) {
      case ResultRoundingRule.TenthsUp:
      case ResultRoundingRule.TenthsDown:
        msLength = 1;
        break;

      case ResultRoundingRule.HundredthsUp:
      case ResultRoundingRule.HundredthsDown:
        msLength = 2;
        break;

      default:
        msLength = 3;
        break;
    }

    result = `${result}.${pad(timeObj.milliseconds, msLength)}`;
  }

  return result;
}

export default formatMilliseconds;
