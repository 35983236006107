import Vue from 'vue';

function stringPadLeft(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

function formatKilometers(meters) {
  let km = (meters || 0) / 1000;
  km = km < 1 ? Math.floor(km * 100) / 100 : Math.floor(km * 10) / 10;
  return km.toLocaleString();
}

function formatTime(seconds) {
  const time = Math.round(+(seconds || 0));
  let h = Math.floor(time / 3600);
  let m = Math.floor((time % 3600) / 60);
  let s = Math.floor((time % 3600) % 60);

  h = stringPadLeft(h, '0', 2);
  m = stringPadLeft(m, '0', 2);
  s = stringPadLeft(s, '0', 2);

  if (h > 0) {
    return `${h}:${m}:${s}`;
  }

  return `${m}:${s}`;
}

function debounce(func, wait, immediate) {
  let timeout;
  return function foo(...args) {
    const context = this;
    const later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function calculateAge(dateString, calcPoint) {
  if (!dateString) {
    return null;
  }
  const point = calcPoint
    ? new Date(calcPoint)
    : new Date();
  const date = new Date(point - new Date(dateString).getTime());
  return Math.abs(date.getUTCFullYear() - 1970);
}

function getQueryParam(item) {
  const value = window.location.search.match(new RegExp(`[?&]${item}=([^&]*)(&?)`, 'i'));
  return value ? value[1] : value;
}

/* eslint-disable no-param-reassign */
function groupBy(data, key) {
  return data.reduce((storage, item) => {
    const group = item[key];
    storage[group] = storage[group] || [];
    storage[group].push(item);
    return storage;
  }, {});
}
/* eslint-enable no-param-reassign */

function formatDate(d, ed) {
  const locale = Vue.prototype.$lang;
  if (!d && !ed) {
    return null;
  }
  const start = new Date(d);
  const end = ed ? new Date(ed) : null;
  const today = new Date();
  const year = start.getFullYear() !== today.getFullYear()
    ? ` ${start.toLocaleDateString(locale, { year: 'numeric' })}`
    : '';

  // One day
  if (!end || start.getDate() === end.getDate()) {
    const from = start.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
    });
    return `${from}${year}`;
  }

  // One month
  if (start.getMonth() === end.getMonth()) {
    const from = start.toLocaleDateString(locale, { day: 'numeric' });
    const to = end.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
    });
    return `${from}–${to}${year}`;
  }

  // One year
  if (start.getFullYear() === end.getFullYear()) {
    const from = start.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'short',
    });
    const to = end.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'short',
    });
    return `${from} – ${to}${year}`;
  }

  const from = start.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
  });
  const to = end.toLocaleDateString(locale, {
    day: 'numeric',
    month: 'short',
  });
  return `${from}${year} – ${to}${year}`;
}

function markQueryInString(str, query) {
  if (!query) {
    return str;
  }
  const q = query.toLowerCase();
  const i = str.toLowerCase()
    .indexOf(q);
  if (i !== -1) {
    const r = str.substring(i, i + q.length);
    return str.replace(r, `<b>${r}</b>`);
  }
  return str;
}

export {
  stringPadLeft,
  formatKilometers,
  formatTime,
  debounce,
  calculateAge,
  getQueryParam,
  groupBy,
  formatDate,
  markQueryInString,
};
